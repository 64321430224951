import React, { useState, useContext } from 'react'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
import Layout from '../components/layout'
import Loader from '../components/loader'
import Logo from '../images/logo.svg'
import AuthContext from '../context/AuthContext'
import { signIn } from '../utils/authUtils'

const SignIn = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { setUser, user, isFetching, setError, error } = useContext(AuthContext)

  const onClickSignIn = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (error) setError(null)
    try {
      const user = await signIn(username, password)
      setUser(user)
      setIsLoading(false)
      navigate('/')
    } catch (err) {
      setIsLoading(false)
      setError(err.message)
      //TODO: show error to user
    }
  }

  const updateFieldValue = (e) => {
    e.preventDefault()
    const { id, value } = e.target
     if (id === 'username') {
      setUsername(value)
     } else if (id === 'password') {
      setPassword(value)
     }
  }

  if (isFetching) return null

  if (user.hasOwnProperty('username')) {
    navigate('/')
    return null
  } 

  return (
    <Layout>
      <div className='sign-in-page'>
        <form>
          <h2>Enter your details</h2>
          <label>Username
            <input id='username' type='text' onChange={updateFieldValue} value={username} />
          </label>
          <label>Password
            <input id='password' type='password' onChange={updateFieldValue} value={password} />
          </label>
          <button
            type='submit'
            id='submit'
            onClick={onClickSignIn}
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : 'Sign in'}
          </button>
        </form>
      </div>
    </Layout>
  )
}

export default SignIn